import { Box, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const DigitialOdyssey = () => {
  return (
    <Box className="digitial_main_box">
      <Container maxWidth="lg">
        <Box className='mobile_img'>
        <img
            src="/static/images/full-stack/woman.webp"
            alt="woman"
            loading="lazy" 
          />
           <img
            src="/static/images/full-stack/man.webp"
            alt="man"
            loading="lazy" 
          />
        </Box>
        <Typography className="digitial_heading">
          Ready to Embark on a Digital Odyssey?
        </Typography>
        <Box className="digitial_box">
          <img
            src="/static/images/full-stack/woman.webp"
            alt="woman"
            className="desktop_img"
            loading="lazy" 
          />
          <Box className="digitial_sub_box">
            <Typography className="digitial_box_text">
              The team behind Stickball's technological ascent is <br /> eager
              to architect your next success.
              <br /> Experience unparalleled innovation, precision, and
              commitment with us.
            </Typography>
            <Button
              className="btn-capability"
              variant="contained"
              component={Link}
              to="/contact-us"
            >
              Engage Our Experts
            </Button>
          </Box>
          <img
            src="/static/images/full-stack/man.webp"
            alt="man"
            className="desktop_img"
            loading="lazy" 
          />
        </Box>
      </Container>
    </Box>
  );
};

export default DigitialOdyssey;
