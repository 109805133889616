import { useEffect } from "react"
import HomeDetailBanner from "./banner";
import HomeDetailSections from "./sections";
import HomeDetailOutcomes from "./outcomes";
import Testimonials from "./testimonials";
import { Grow } from "@mui/material";

export default function HomeDetail() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [])
    return (
        <>
            <Grow in={true} timeout={1000}>
                <div>
                    <HomeDetailBanner />
                </div>
            </Grow>
            <HomeDetailSections />
            <HomeDetailOutcomes />
            <Testimonials />
        </>
    )
}