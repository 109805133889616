import { lazy } from 'react'
import Programs from '../components/programs'
import Solutions from '../components/solutions'
import ConnectWithUs from '../components/connect-with-us'
import JoinMission from '../components/join-mission'
import HomeDetail from '../components/home-details/homeDetail'
import FullStack from '../components/full-stack'

const Home = lazy(() => import('../components/home'));
const AboutUsCom = lazy(() => import('../components/about-us'));
const ContactUs = lazy(() => import('../components/contact-us'));
const TermServices = lazy(() => import('../components/termServices'));
const HireStickballTeam = lazy(() => import('../components/hire-stickball-team'));

export const paths = [
    {
        title: 'Home',
        route: '/',
        element: <Home />
    },
    {
        title: 'About Us',
        route: '/about-us',
        element: <AboutUsCom />
    },
    {
        title: 'Contact Us',
        route: '/contact-us',
        element: <ContactUs />
    },
]

export const innerPaths = [
    {
        route: '/college-students',
        element: <Programs />
    },
    {
        route: '/corporations',
        element: <Solutions />
    },
    {
        route: '/communities',
        element: <ConnectWithUs />
    },
    {
        route: '/sponsors',
        element: <JoinMission />
    },
    {
        route: '/k-12-financial-education',
        element: <HomeDetail />
    },
    {
        route: '/terms-of-services',
        element: <TermServices />
    },
    {
        route: '/hire-stickball-team',
        element: <HireStickballTeam />
    },
    {
        route: '/full-stack-development',
        element: <FullStack />
    },
]