import { Container, Typography, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";

const DiveCapabilities = () => {
  return (
    <section className="capabilities-section">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item className="capabilities" xs={12} md={7} lg={9}>
            <Typography className="exprtise" component={'h1'}>
              Where Expertise Meets Execution
            </Typography>
            <Typography className="story">
              The Stickball Success{" "}
              <span>
                Story
                <img src="/static/images/full-stack/crown.webp" alt="crown" loading="lazy" />
              </span>
            </Typography>
            <Typography className="engineering_prowess">
              Leverage the engineering prowess behind Stickball's digital
              triumph for your next venture
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} lg={3}>
            <Button
              className="btn-capability"
              variant="contained"
              component={Link}
              to="/contact-us"
            >
              Dive into our Capabilities!
            </Button>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default DiveCapabilities;
