import { useEffect } from 'react'
import {
    Container,
    Grid,
    Typography,
    Box,
    Button,
} from '@mui/material'
import { Link } from "react-router-dom";

export default function JoinMission() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [])
    return (
        <section className="join-section">
            <Container maxWidth="lg">
                <Grid container spacing={3} className="category">
                    <img width="70%" height='70%' src={"/static/images/join-mission/partner.png"} alt="partner-join-mission" loading='lazy' />
                    <Box className="text-section">
                        <Typography className="title">  Become a <span className="text-primary"> partner </span>  in shaping lives</Typography>
                        <Typography className="paragraph">
                            Your support can make a lasting difference in the lives of students and communities. By sponsoring <b>Stickball</b>,
                            you join a mission that transcends mere education - a mission that teaches lessons that stick and life skills that last.
                            Align your brand with empowerment, success, and positive change. <br />
                            <b>Let's together build a brighter tomorrow</b>
                        </Typography>
                    </Box>
                    <Button
                        component={Link} to='/contact-us'
                        sx={{ mt: 7 }} className="btn-mission">Join the mission</Button>
                </Grid >
            </Container>
        </section>
    )
}
