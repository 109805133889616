import { Suspense, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from '../components/common/header'
import Footer from '../components/common/footer'
import { paths, innerPaths } from '../utils/paths'
import { Toaster } from 'react-hot-toast'
import NavigationIcon from "@mui/icons-material/Navigation";
import 'animate.css'
import { useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const LogoLoader = () => (
  <div style={{ height: '100vh', textAlign: 'center', display: 'grid', placeItems: 'center' }}>
    <img src="/static/images/logo.gif" width={'300vw'} height={'auto'} alt="logo-header-loader" loading="lazy" />
  </div>
)

function App() {
  const location = useLocation();
  const [title, setTitle] = useState("Stickball Empower People - Life Skills & Financial Education")
  const [description, setDescription] = useState("Welcome to Stickball Your Trusted Source for Life Skills and Financial Education. We empower tomorrow's leaders with practical knowledge and tools to excel in life.")

  useEffect(() => {
    const scrollFunction = () => {
      let mybutton = document.getElementById("myBtn1");
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    };

    window.addEventListener("scroll", scrollFunction);

    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  }, []);

  const updateTitleAndDescription = () => {
    switch (location.pathname) {
      case "/k-12-financial-education":
        setTitle("Stickball - Provides Financial Literacy for K-12 to Achieve Goal");
        setDescription("Stickball unlocks a brighter financial future for K-12 students. Explore our engaging financial education curriculum tailored for young learners.");
        break;
      case "/college-students":
        setTitle("Elevate Your College Journey with Smart Money Skills - Stickball");
        setDescription("Stickball helps college students to Learn money management skills from our expert resources. Financial literacy is key to college success.");
        break;
      case "/communities":
        setTitle("Joining Hands for Community Empowerment | Stickball Initiatives");
        setDescription("Discover Stickball's corporate initiatives that empower communities and inspire positive change. Join us in building a legacy of stronger, thriving neighborhoods.");
        break;
      case "/full-stack-development":
        setTitle("Master Full Stack Development: Expertise at Stickball");
        setDescription("Unlock the art of Full Stack Development with Stickball. Our expertise empowers you to master the entire development spectrum, from planning to execution.");
        break;
      case "/terms-of-services":
        setTitle("Terms of Services - Stickball");
        setDescription("Review the terms of service agreement between Stickball and our valued users. Understand the guidelines and commitments that govern our relationship.");
        break;
      default:
        setTitle("Stickball Empower People - Life Skills & Financial Education");
        setDescription("Welcome to Stickball Your Trusted Source for Life Skills and Financial Education. We empower tomorrow's leaders with practical knowledge and tools to excel in life.");
        break;
    }
  };

  useEffect(() => {
    updateTitleAndDescription();
  }, [location.pathname]);

  const topFunction = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };

  const getCanonicalUrl = (path) => {
    return `https://stickball.biz${path}`;
  };

  return (
    <>
      <HelmetProvider>
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
        <Helmet>
          <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={description} />
        </Helmet>
        <Suspense fallback={<LogoLoader />}>
          <Header />
          <Routes>
            {
              paths?.map((path, index) => (<Route exact key={index} path={path.route} element={path.element} />))
            }
            {
              innerPaths?.map((path, index) => (<Route exact key={index} path={path.route} element={path.element} />))
            }
          </Routes>
          <Footer />
        </Suspense>
        <div className="GOTOTOP">
          <button onClick={topFunction} id="myBtn1" title="Go to top">
            <NavigationIcon />
          </button>
        </div>
      </HelmetProvider>
    </>
  );
}

export default App;