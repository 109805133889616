import { Box, Card, Container, Typography } from "@mui/material";

const processData = [
  {
    id: "01",
    heading: "Initial Meeting",
    description:
      "A collaborative session to understand the unique challenges, goals, and aspirations of our clients. This ensures we align our strategies with your vision.",
    imgurl: "/static/images/full-stack/initialization.webp",
  },
  {
    id: "02",
    heading: "Prototyping",
    description:
      "Our experts craft detailed Figma prototypes, bringing conceptual ideas to life. This tangible representation ensures that all stakeholders share a unified vision before moving to development.",
    imgurl: "/static/images/full-stack/prototyping.webp",
  },
  {
    id: "03",
    heading: "Estimation",
    description:
      "We offer a transparent breakdown of the project, detailing the required team, timeframe, and resources. By setting clear expectations, we avoid pitfalls and ensure efficiency.",
    imgurl: "/static/images/full-stack/estimation.webp",
  },
  {
    id: "04",
    heading: "Agile Development",
    description:
      "An iterative approach that allows flexibility and adaptability. Regular check-ins, feedback loops, and adaptive planning mean we're always aligned with the project’s objectives and can make necessary adjustments in real-time.",
    imgurl: "/static/images/full-stack/agile-development.webp",
  },
  {
    id: "05",
    heading: "Deployment",
    description:
      "Our expertise extends to deploying applications on leading platforms like AWS, Azure, GCP, ensuring scalability, reliability, and security.",
    imgurl: "/static/images/full-stack/deployment.webp",
  },
  {
    id: "06",
    heading: "Support",
    description:
      "Beyond the development phase, our commitment remains. With monthly support, we guarantee optimal application performance and swiftly address any issues.",
    imgurl: "/static/images/full-stack/support.svg",
  },
];

const ProcessApproach = () => {
  return (
    <Box className="process_main_box">
      <Container maxWidth="lg">
        <Box className="process_box">
          <img
            src="/static/images/full-stack/process.webp"
            alt="process"
            className="process_img"
            loading="lazy" 
          />
          <Typography className="process_heading">
            Process & Approach
          </Typography>
        </Box>

        <Box className="process_card_main_container">
          {processData.map((process) => (
            <Card className="process_card" key={process.id}>
              <Box className="process_card_box">
                <Typography className="process_card_heading">
                  <span>{process.id}</span>
                  {process.heading}
                </Typography>
                <Typography className="process_card_description">
                  {process.description}
                </Typography>
              </Box>
              <Box
                className={`process_img_box ${
                  process.id === "03" ? "process_img_padding" : ""
                }`}
              >
                <img src={process.imgurl} alt={process.heading} loading="lazy" />
              </Box>
            </Card>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default ProcessApproach;
