import React, { useEffect } from 'react'
import { Container, Grow } from '@mui/material'
import Banner from '../common/Banner'
import Category from '../programs/Category'




export default function Solutions() {
    const content = {
        left: `Turning Data into Decisions: <br  />
        Upskill in Analytical Expertise, Financial Literacy, <br  />  and Life Skills for the Modern Corporation`,
        right: `Empower your corporation with our cutting-edge educational solutions. Whether it's financial mastery, essential life skills, or 
        analytical expertise, Stickball is your partner in creating a workforce that's not only skilled but also adaptable, engaged, and 
        ready for success. Experience the future of corporate learning today`
    }
    const mainContent = [
        {
            text: `In today's data-driven world, analytical skills are a corporate necessity. Our Analyst 201 Certification program helps your
             employees master these skills, whether it's interpreting complex data, forecasting trends, or making strategic business decisions. 
             <br  /> Delivered through engaging immersive technology and tailored to your company's specific requirements, this certification 
             ensures your team is ready to meet the demands of the modern business landscape.`,
            title: `Advanced analytical skills <span class="text-primary">for modern business challenges</span>`,
            image: '/static/images/solutions/analytical-skills.png',
            type: 'left',
            beforeTitle: 'Analyst 201 certification for employees',
        },
        {
            text: `Equip your employees with essential financial know-how through our specialized Financial Literacy program.
             <br  /> Crafted to align with both federal and state standards, this program uses immersive technology to guide 
             employees through the intricate realms of budgeting, investing, taxes, and more. Customizable to suit your corporate 
             environment, it's an investment that pays dividends in employee satisfaction and financial empowerment.`,
            title: `Empowering your workforce with <span class="text-primary"> fiscal knowledge </span>`,
            image: `static/images/solutions/fiscal-know.png`,
            type: `right`,
            beforeTitle: `Financial literacy for employees`,
        },
        {
            text: `Our weekly Life Skills program is a game-changer for corporate culture. Designed for all employees, it teaches 
            essential soft skills like communication, time management, emotional intelligence, and problem-solving. Utilizing immersive 
            tech, these lessons come to life, fostering a more engaged and effective workforce. <br  /> Customized to your company's unique
             needs, this is more than a program, it's a pathway to personal and professional growth.`,
            title: `<span class="text-primary">Unlocking potential: </span> learning, working, growing together`,
            image: `/static/images/solutions/unlock-potential.png`,
            type: `left`,
            beforeTitle: `Life skills program for employees`,
        }

    ]
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [])
    return (
        <>
            <Grow in={true} timeout={1000}>
                <div>
                    <Banner bg="url('/static/images/solutions/banner-bg.webp')" content={content} />
                </div>
            </Grow>
            <section className="main-body">
                <Container maxWidth="lg">
                    {
                        mainContent?.map((item, index) => {
                            return (
                                <Category {...item} comKey={index} />
                            )
                        })
                    }
                </Container>
            </section>
        </>
    )
}
