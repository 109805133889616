import { useEffect } from "react";
import DigitialOdyssey from "./DigitialOdyssey";
import DiscoveryPhase from "./DiscoveryPhase";
import DiveCapabilities from "./DiveCapabilities";
import ProcessApproach from "./ProcessApproach";
import TechSolution from "./TechSolution";
import { Grow } from "@mui/material";

export default function FullStack() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Grow in={true} timeout={1000}>
        <section className="dev-banner" />
      </Grow>
      <DiveCapabilities />
      <TechSolution />
      <DiscoveryPhase />
      <ProcessApproach />
      <DigitialOdyssey />
    </>
  );
}
