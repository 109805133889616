import {
    Box,
    Typography,
    Grid,
} from "@mui/material"

export default function HomeDetailBanner() {
    return (
        <Box
            sx={{
                mt: '-3rem',
                width: "100%",
                height: "42rem",
                backgroundImage: "url(static/images/home-details/coverPhoto.webp)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
            }}
        >
            <Grid container className="banner_text" gap={4} py={6}>
                <Grid item lg={0.5} md={0.5} sm={0.2} xs={0.2} />
                <Grid item lg={5} md={5} sm={5} xs={5}>
                    <Typography variant="h5" fontWeight={700} sx={{ fontSize: { xs: '0.9rem', md: '1.5rem' } }}>
                        Shaping Success from Classroom to Life: Financial Literacy, Life Skills, and Analytical Expertise for K-12 Learners
                    </Typography>
                </Grid>
                <Grid item lg={5} md={5} sm={4} xs={4}>
                    <Typography variant="caption" fontWeight={500} sx={{ fontSize: { xs: '0.5rem', md: '0.9rem' } }}>
                        At Stickball, we don't just educate; we empower. Join us in making lessons that stick and providing life skills that last. Explore our offerings and find the perfect fit for your student body. Let's shape the future together                   </Typography>
                </Grid>
                <Grid item lg={0.5} md={0.5} sm={0.2} xs={0.2} />
            </Grid>
        </Box>
    )
}