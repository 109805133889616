import { Box, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { paths } from "../../utils/paths";

const FooterMenuItem = ({ route, title }) => (
    <Box className="footer_menu_sub_box">
        <img src="/static/images/footer/footer_hover_arrow.svg" alt="arrow" loading="lazy" width={13} height={'100%'} />
        <Typography to={route} component={Link} className="footer_menu">
            {title}
        </Typography>
    </Box>
);

const AudienceMenuItem = ({ label, link }) => (
    <Box className="footer_menu_sub_box">
        <img src="/static/images/footer/footer_hover_arrow.svg" alt="arrow" loading="lazy" width={13} height={'100%'} />
        <Typography className="footer_menu" component={Link} to={link}>
            {label}
        </Typography>
    </Box>
);

const Footer = () => {
    const items = [
        { label: "For K-12", to: "/k-12-financial-education" },
        { label: "For Colleges", to: "/college-students" },
        { label: "For Corporations", to: "/corporations" },
        { label: "For Community", to: "/communities" },
        { label: "For Sponsors", to: "/sponsors" },
        { label: "Full Stack Development", to: "/full-stack-development" },
    ];

    return (
        <Box className="footer">
            <Container maxWidth="lg">
                <Grid container className="footer-box">
                    <Grid item className="footer_sub_box" xs={12} md={7}>
                        <img
                            src="/static/images/logo-white.gif"
                            alt="logo-footer"
                            className="footer_logo"
                            loading="lazy"
                        />
                        {/* <Box>
                            <Box className="footer_text_box">
                                <Typography className="terms" >
                                    60 State Street, Suite 1100 Boston, MA 02109
                                </Typography>
                            </Box>
                            <Box className="footer_text_box">
                                <Typography
                                    className="terms"
                                    component={Link}
                                    to="/terms-of-services"
                                >
                                    Terms of Service
                                </Typography>
                                <Typography className="rights">
                                    © All rights reserved Stickball 2023
                                </Typography>
                            </Box>
                        </Box> */}
                    </Grid>
                    <Grid item xs={12} md={2.5} className="footer_menu_box">
                        {paths.map(({ route, title }) => (
                            <FooterMenuItem key={route} route={route} title={title} />
                        ))}
                    </Grid>
                    <Grid item xs={12} md={2.5} className="footer_menu_box">
                        {items.map((item, index) => (
                            <AudienceMenuItem key={index} label={item.label} link={item.to} />
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="m_footer_text_box">
                            <Typography
                                className="m_terms"
                                component={Link}
                                to="/terms-of-services"
                            >
                                Terms of Service
                            </Typography>
                            <Typography className="m_rights">
                                © All rights reserved Stickball 2023
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;