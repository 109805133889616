import {
    Box,
    Grid,
    Container,
    Typography
} from "@mui/material";

export default function Banner({ bg, content }) {
    return (
        <section className="programs-banner" style={{ backgroundImage: bg }}>
            <Box className="banner-content">
                <Container maxWidth="lg">
                    <Grid container spaciing={3} alignItems={"center"}>
                        <Grid item md={6} sm={12}>
                            <Typography className="text-left">
                                <span dangerouslySetInnerHTML={{ __html: content.left }}></span>
                            </Typography>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Typography className="text-right">
                                <span dangerouslySetInnerHTML={{ __html: content.right }}></span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </section>
    )
}