import {
    Box,
    Typography,
    Grid,
    Card,
    CircularProgress,
} from "@mui/material"

export default function HomeDetailOutcomes() {
    return (
        <Grid className="cards-section" container sx={{
            backgroundImage: "url(static/images/home-details/BG.svg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            textAlign: 'center',
            justifyContent: 'center'
        }} spacing={2} py={10} my={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} mb={3}>
                <Box className="tomorrow-leaders" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography className="title" sx={{ fontSize: '40px !important' }}>
                        <span className="heading-underline">Outcomes
                            <img src="/static/images/home-details/underline2.svg" alt="underline2-outcomes" style={{ width: 225, height: 9.993, marginLeft: '-10px' }} />
                        </span> that matter
                    </Typography>
                    <img src="/static/images/home-details/arrow.svg" alt="arrow-outcomes" style={{ width: 40, marginLeft: 20, marginTop: 25 }} />
                </Box>
            </Grid>
            <Grid item xs={11} sm={11} md={3.75} lg={3.75}>
                <Card sx={{ borderRadius: '1.875rem' }}>
                    <Grid container py={3} justifyContent="center" spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                <CircularProgress thickness={4} variant="determinate" value={100} size={'100px'} sx={{ color: '#D7D7D7' }} />
                                <CircularProgress
                                    variant="determinate"
                                    thickness={4}
                                    value={40}
                                    size={'100px'}
                                    sx={{
                                        color: '#FB785C',
                                        position: 'absolute',
                                        strokeLinecap: "round",
                                        top: 0,
                                    }}
                                />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="h6" sx={{ fontSize: 24, fontWeight: 800 }}>
                                        40 %
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={7} sx={{
                            alignSelf: 'center', textAlign: 'left'
                        }} >
                            <Typography variant="subtitle" fontSize={14} fontWeight={500}>
                                Improvement in national standard assessment financial literacy score
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={11} sm={11} md={3.75} lg={3.75}>
                <Card sx={{ borderRadius: '1.875rem' }}>
                    <Grid container py={3} justifyContent="center" spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                <CircularProgress thickness={4} variant="determinate" value={100} size={'100px'} sx={{ color: '#D7D7D7' }} />
                                <CircularProgress
                                    variant="determinate"
                                    thickness={4}
                                    value={90}
                                    size={'100px'}
                                    sx={{
                                        color: '#FB785C',
                                        position: 'absolute',
                                        strokeLinecap: "round",
                                        top: 0,
                                    }}
                                />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="h6" sx={{ fontSize: 24, fontWeight: 800 }}>
                                        90 %
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={7} sx={{ alignSelf: 'center', textAlign: 'left' }} >
                            <Typography variant="subtitle" fontSize={14} fontWeight={500}>
                                Average national standard assessment financial literacy score after completing lessons                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={11} sm={11} md={3.75} lg={3.75}>
                <Card sx={{ borderRadius: '1.875rem' }}>
                    <Grid container py={3} justifyContent="center" spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                <CircularProgress thickness={4} variant="determinate" value={100} size={'100px'} sx={{
                                    color: '#FB785C',
                                }} />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 800 }}>
                                        4-6 <br /> Weeks
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={7} sx={{ alignSelf: 'center', textAlign: 'left' }} >
                            <Typography variant="subtitle" fontSize={14} fontWeight={500}>
                                Average lesson completion time by students
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid >
        </Grid >
    )
}