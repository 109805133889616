import { useState } from "react"
import {
    Box,
    Typography,
    Grid,
    Card,
    IconButton,

} from "@mui/material"
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const imonialData = [
    {
        title: 'Student Grade 10',
        detail: ` Never thought I would enjoy learning finance, but Stickball
        changed my mind. The tech and real-life scenarios made me more
        financially literate and confident.`
    },
    {
        title: 'Financial Literacy Teacher',
        detail: ` Stickball has been a game-changer for my classroom. The engaging
        content and real-life scenarios help students understand finance
        and build confidence.`
    },
    {
        title: 'Student Grade 12',
        detail: `I like how Stickball mixes things up. The chatbots, podcasts,
        and TikToks make it more interesting, and the practice exercises
        are much more life-like than anything else we do in school.`
    },
]



export default function Testimonials() {

    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = imonialData.length - 1;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep < 3 ? prevActiveStep + 1 : prevActiveStep);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep > -1 ? prevActiveStep - 1 : prevActiveStep);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Grid className="cards-section" container sx={{
            backgroundImage: "url(static/images/home-details/BG.svg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            textAlign: 'center',
            justifyContent: 'center'
        }} py={10} my={2} >
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h4" sx={{
                    color: '#616DED', textAlign: 'center', fontWeight: 'bold', mb: 5,
                }} >
                    <span className="heading-underline">
                        {"Testimonials "}
                        <img src="/static/images/home-details/underline3.svg" style={{ width: '14rem', height: '0.7rem', marginLeft: 10 }} alt='underline3-testimonials' />
                    </span>
                </Typography>
            </Grid>
            <Grid item xs={0} sm={0} md={1} lg={1} />
            <Grid item xs={1} sm={1} md={1} lg={1} alignSelf={'center'} textAlign={'center'} >
                <IconButton
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    sx={{ opacity: activeStep === 0 ? 0.3 : 1 }}
                >
                    <img src="static/images/home-details/backArrow.svg" style={{ width: "200%", height: "200%" }} alt='backArrow-testimonials' />
                </IconButton>
            </Grid>
            <Grid item xs={9} sm={9} md={6} lg={6}>
                <Card sx={{ pt: 13, pb: 4, borderRadius: '30px', display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ maxWidth: '90%' }}>
                        <AutoPlaySwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {imonialData.map((item, index) => (
                                <div key={item.title}>
                                    {Math.abs(activeStep - index) <= 2 ? (
                                        <>
                                            <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold' }}  >
                                                {item.title}
                                            </Typography>
                                            <img src="static/images/home-details/comasStart.svg" alt='comasStart-testimonials' />
                                            <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold', my: 2 }} >
                                                {item.detail}
                                            </Typography>
                                            <img src="static/images/home-details/comasEnd.svg" style={{ marginLeft: '-17%' }} alt='comasEnd-testimonials' />
                                        </>
                                    ) : null}
                                </div>
                            ))}
                        </AutoPlaySwipeableViews>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} alignSelf={'center'} >
                <IconButton
                    onClick={handleNext}
                    disabled={activeStep === maxSteps}
                    sx={{ opacity: activeStep === maxSteps ? 0.3 : 1 }}
                >
                    <img src="static/images/home-details/forwardArrow.svg" style={{ width: "200%", height: "200%" }} alt="forwardArrow-testimonials" />
                </IconButton>
            </Grid>
            <Grid item xs={0} sm={0} md={1} lg={1} />
        </Grid >
    )
}