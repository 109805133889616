import { useEffect } from 'react'
import {
    Container,
    Grid,
    Typography,
    Box,
    Button,
} from '@mui/material'
import { Link } from "react-router-dom";

export default function ConnectWithUs() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [])
    return (
        <section className="connect-session">
            <Container maxWidth="lg">
                <Grid container spacing={3} className="category">
                    <Grid item md={4} sm={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: { md: 8, sm: 2 } }}>
                        <img className="connect-img" src={"/static/images/connect-with-us/left.png"} alt="connect-with-us" loading='lazy' />
                    </Grid>
                    <Grid item md={8} sm={12} className="text-section" sx={{ padding: { md: '40px', sm: '20px', xs: '10px' } }}>
                        <Box>
                            <Typography className="title">  Strengthening communities, <br /><span className="text-primary">one skill at a time</span> </Typography>
                            <Typography className="paragraph">
                                At <b>Stickball</b>, we believe in the power of community. Our specially crafted programs aim at empowering individuals within communities
                                to thrive, grow, and contribute positively to the world around them. Whether you're a parent, educator, or community leader, join
                                us in creating a network of learning that not only educates but also inspires. <br />
                                <b>Together, we can make lessons stick and create a legacy of empowered citizens</b>
                            </Typography>
                        </Box>
                        <Button
                            component={Link} to='/contact-us'
                            sx={{ mt: 7 }} className="btn-mission">Join the mission</Button>
                    </Grid>
                </Grid >
            </Container>

        </section>
    )
}
