import {
    Button,
    Box,
    Typography,
    Grid,
    Card
} from "@mui/material"
import { Link } from "react-router-dom";

export default function HomeDetailSections() {
    return (
        <>
            <section className="cards-section">
                <Card className="not-shadow" elevation={0}>
                    <Grid container
                        sx={{
                            px: {
                                md: '80px', sm: '20px', xs: '10px', lg: '100px'
                            },
                            py: {
                                md: '80px', sm: '20px', xs: '10px', lg: '100px'
                            }
                        }}
                    >
                        <Grid item xs={12} md={7} sm={12} lg={7}>
                            <Box className="tomorrow-leaders">
                                <Typography className="mainTitle" component={'h2'}>Financial literacy</Typography>
                                <Typography className="title"> <span>Empower your students with </span>
                                    <span className="heading-underline1" style={{ color: 'black' }}>
                                        money smarts
                                        <img src="/static/images/home-details/underline.svg" alt="underline-sections" loading="lazy" />
                                    </span>
                                </Typography>
                                <Typography className="paragraph" mb={1}>
                                    Navigating the world of money and finance doesn't have to be daunting. Our Financial Literacy program for K-12 is crafted in alignment with federal and state standards.
                                </Typography>
                                <Typography className="paragraph">
                                    Utilizing immersive technology, we create engaging and interactive experiences that teach students the essential principles of personal finance, budgeting, saving, and investing. It's never too early to build a financially responsible future, and with our customizable solutions, we make sure the lessons fit your school's specific needs.                                </Typography>
                            </Box>
                            <Button component={Link} to="/contact-us" className="btn-learn-more" sx={{ textTransform: 'capitalize', mt: 7 }}>Ask for demo</Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} sx={{ mt: '-70px' }}>
                            <img src="/static/images/home-details/Financial-Literacy.svg" width={'110%'} height={'auto'} alt="Financial-Literacy" loading="lazy" />
                        </Grid>
                    </Grid>
                </Card>
            </section>
            <section className="cards-section">
                <Card className="not-shadow" elevation={0}>
                    <Grid container
                        sx={{
                            px: {
                                md: '80px', sm: '20px', xs: '10px', lg: '100px'
                            },
                            py: {
                                md: '10px', sm: '5px', xs: '5px', lg: '10px'
                            }
                        }}
                    >
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <img src="/static/images/home-details/Life-Skills-Program.svg" width={'90%'} height={'auto'} alt="Life-Skills-Program" loading="lazy" />
                        </Grid>
                        <Grid item xs={12} md={8} sm={12} lg={8}>
                            <Box className="tomorrow-leaders">
                                <Typography className="mainTitle" component={'h2'}>Life skills program</Typography>
                                <Typography className="title"> <span>Equip your</span> high schoolers <span>for success in life
                                </span></Typography>
                                <Typography className="paragraph" mb={1}>
                                    Life is more than textbooks and exams. Our Life Skills program is designed to teach all 9-12 students how to learn, work, grow, and live with essential life skills. Delivered weekly and enhanced with immersive technology, we engage students in real-world scenarios, building skills in communication, problem-solving, teamwork, and more.
                                </Typography>
                                <Typography className="paragraph">
                                    Tailored to your school's student body, this program is not just education – <span style={{ color: '#FB785C' }}> it's preparation for life.</span>
                                </Typography>
                            </Box>
                            <Button component={Link} className="btn-learn-more" to="/contact-us" variant="outlined" sx={{ textTransform: 'capitalize', mt: 7 }}>Ask for demo</Button>
                        </Grid>
                    </Grid>
                </Card>
            </section>
            <section className="cards-section">
                <Card className="not-shadow" elevation={0} >
                    <Grid container gap={7}
                        sx={{
                            px: {
                                md: '80px', sm: '20px', xs: '10px', lg: '100px'
                            },
                            py: {
                                md: '80px', sm: '20px', xs: '10px', lg: '100px'
                            }
                        }}
                    >
                        <Grid item xs={12} md={7} sm={12} lg={7} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Box className="tomorrow-leaders">
                                <Typography className="mainTitle" component={'h2'}>Analyst 101</Typography>
                                <Typography className="title"> <span>Developing</span> analytical Minds <span>for the modern world</span></Typography>
                                <Typography className="paragraph">
                                    In a world driven by data, analytical skills are paramount. Our Analyst 101 program for 9-12 students dives into the critical aspects of analytical thinking, teaching how to analyze, interpret, and utilize data for work and life. Enhanced with immersive tech, our engaging lessons are not just informative but inspiring. Customizable to your school's unique needs, we're here to foster the next generation of critical thinkers.
                                </Typography>
                            </Box>
                            <Button component={Link} className="btn-learn-more" to="/contact-us" variant="outlined" sx={{ textTransform: 'capitalize', mt: 7 }}>Ask for demo</Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <img src="/static/images/home-details/Analyst-101.svg" width={'100%'} height={'auto'} alt="Analyst-101" loading="lazy" />
                        </Grid>
                    </Grid>
                </Card>
            </section>
        </>

    )
}