import { useState, useEffect } from "react";
import {
  AppBar,
  Button,
  Toolbar,
  Box,
  Grid,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Container,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { paths } from "../../utils/paths";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false)
  }, [location.pathname])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const toggleDrawer = (isOpen) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(isOpen);
  };

  const quickNavData = [
    {
      title: "K-12",
      to: "/k-12-financial-education",
    },
    {
      title: "COLLEGE STUDENTS",
      to: "/college-students",
    },
    {
      title: "CORPORATIONS",
      to: "/corporations",
    },
    {
      title: "COMMUNITIES",
      to: "/communities",
    },
    {
      title: "SPONSORS",
      to: "/sponsors",
    },
    {
      title: "FULL STACK DEVELOPMENT",
      to: "/full-stack-development",
    },
  ];

  return (
    <AppBar position="fixed" className="header">
      <Toolbar className="nav-toolbar">
        <Container maxWidth="lg">
          <Grid container className="nav-container">
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "flex-start",
              }}
            >
              <IconButton
                aria-label="menu"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon className="menu-icon" />
              </IconButton>

              <Drawer
                anchor="left"
                open={open}
                onClose={toggleDrawer(false)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  "& .MuiDrawer-paper": {
                    width: 300,
                  },
                }}
              >
                <Box
                  role="presentation"
                  className="drawer"
                >
                  <List>
                    {paths.map((path, index) => (
                      <ListItem
                        onClick={() => {
                          navigate(path.route)
                        }}
                        onKeyDown={toggleDrawer(false)}
                        key={index}
                        className={
                          location.pathname === path.route
                            ? "active"
                            : "nav_link"
                        }
                      >
                        <ListItemText primary={path.title} />
                      </ListItem>
                    ))}
                  </List>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Audiences</Typography>
                    </AccordionSummary>
                    <AccordionDetails onClick={toggleDrawer(false)}>
                      {quickNavData?.map((item) => {
                        return (
                          <MenuItem onClick={handleClose} key={item.to}>
                            <Box
                              sx={{ width: "100%", textAlign: "center" }}
                              component={Button}
                              onClick={() => {
                                toggleDrawer(false);
                                navigate(item.to);
                              }}
                              className="fork"
                            >
                              {item.title}
                            </Box>
                          </MenuItem>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Drawer>
            </Box>
            <Link to="/">
              <img className="logo" src="/static/images/logo.gif" alt="logo-header" loading="lazy" />
            </Link>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "space-between",
                gap: "40px",
                paddingRight: "20px",
              }}
            >
              {paths.map(({ route, title }) => (
                <Button
                  key={route}
                  to={route}
                  component={Link}
                  className={
                    location.pathname === route ? "active" : "menu-item"
                  }
                >
                  {title}
                </Button>
              ))}
              <Button
                className="menu-item"
                endIcon={<ArrowDropDownIcon />}
                id="basic-button"
                aria-controls={openMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                onClick={handleClick}
              >
                Audiences
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {quickNavData?.map((item) => {
                  return (
                    <MenuItem onClick={handleClose} key={item.title}>
                      <Box
                        sx={{ width: "100%", textAlign: "center" }}
                        component={Button}
                        onClick={() => {
                          navigate(item.to);
                        }}
                        className="fork"
                      >
                        {item.title}
                      </Box>
                    </MenuItem>
                  );
                })}
              </Menu>
            </Box>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;