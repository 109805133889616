import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const DiscoveryPhase = () => {
  return (
    <Box className="discovery_main_box">
      <Container maxWidth="lg">
        <Grid container className="flex-between">
          <Grid item className="discovery_left_side" xs={12} md={6}>
            <img src="/static/images/full-stack/search.webp" alt="search" loading="lazy" />
            <Box className="discovery_left_side_box">
              <Typography className="discovery_phase">
                The Discovery Phase
              </Typography>
              <Typography className="foundation">
                Foundation for Success & Expert Guidance
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className="discovery_text">
              This pivotal stage sets the course for the entire project. We
              engage in meticulous requirement gathering, brainstorming, and
              aligning our visions. <br />
              <br />
              Our "<span>CTO as a Service</span>" seamlessly integrates into
              this phase, offering expert advice on tech architecture and
              optimal team structures. This holistic approach ensures the
              project’s success from the outset.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button className="discovery_btn" component={Link} to="/contact-us">
              Begin Discovery Phase
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DiscoveryPhase;
