import { Box, Card, Container, Grid, Typography } from "@mui/material";

const TechSolution = () => {
  const techCardData = [
    {
      name: "SPA Development",
      description:
        "Building single-page applications that provide a seamless user experience, reducing reload times and enhancing interactivity.",
    },
    {
      name: "PWA Development",
      description:
        "Leveraging the best of web and mobile apps, we create progressive web apps that offer offline capabilities, push notifications, and an app-like experience.",
    },
    {
      name: "Mobile Development",
      description:
        "Tailored mobile solutions designed for user engagement and optimized performance, ensuring accessibility across devices and platforms.",
    },
    {
      name: "LLM Development",
      description:
        "Crafting leading Learning Lifecycle Management systems that support, track, and enhance the educational journey from start to finish.",
    },
    {
      name: "ChatGPT Apps Development",
      description:
        "Implementing cutting-edge conversational AI to foster better user engagement, provide instantaneous support, and offer personalized experiences.",
    },
    {
      name: "Metaverse Development",
      description:
        "Stepping into the future, we design immersive experiences and virtual worlds, merging digital and physical realities.",
    },
  ];
  return (
    <Box className="bg_tech">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} md={4} className="tech_text_container">
            <img
              src="/static/images/full-stack/cloud.webp"
              alt="cloud"
              className="cloud_img"
              loading="lazy" 
            />
            <Box className="tech_text_box">
              <Typography className="tech_text">Our</Typography>
              <Typography className="tech_text">
                Tech{" "}
                <span>
                  Solutions
                  <img src="/static/images/full-stack/line.webp" alt="line" loading="lazy" />
                </span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} className="tech_card_container">
            {techCardData.map((data) => (
              <Card className="tech_card" key={data.name}>
                <Typography className="tech_card_heading" component={'h2'}>
                  {data.name}
                </Typography>
                <Typography className="tech_card_desc">
                  {data.description}
                </Typography>
              </Card>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TechSolution;
