import { useEffect } from 'react'
import { Container, Grow } from '@mui/material'
import Banner from '../common/Banner'
import Category from './Category'

export default function Programs() {
    const content = {
        left: `Empowering College Journeys: <br  />
        Advanced Analytical Training, Financial Literacy, <br  /> and Essential Life Skills for a Thriving Future.`,
        right: `Empower your college with innovative educational solutions that resonate with today's generation. Our products, 
        designed to engage and inspire, are more than mere lessons; they are tools for success, laying the foundation for a brighter
         future. Experience education redefined with Stickball.`
    }
    const mainContent = [
        {
            text: `Prepare your students for the complexities of the modern world with our Analyst 201 Certification. This program,
             tailored for college-level learners, builds essential analytical skills that are invaluable for both work and life.
              Using immersive technology to create an engaging learning experience, it's designed to help students interpret data, make informed 
              decisions, and think critically. <br  /> Customizable to your institution's specific needs, it's an investment in intellectual empowerment.`,
            title: `<span class="text-primary"> Enhancing </span> analytical acumen <span class="text-primary">for tomorrow's leaders</span>`,
            image: '/static/images/programs/analytics.png',
            type: 'right',
            beforeTitle: 'Analyst 201 - certification for college',
        },
        {
            text: `Financial awareness is key to independence, and our Financial Literacy program, aligned to both federal and state standards, 
            aims to empower college students with that knowledge. Covering essential topics such as budgeting, investing, and credit management, 
            this program employs immersive tech to make learning both engaging and effective. <br  /> Tailored to fit your school's student body, it lays 
            the groundwork for a financially savvy and responsible adulthood.`,
            title: `<span class="text-primary">Secure their future with </span> financial mastery`,
            image: `static/images/programs/secure.png`,
            type: `left`,
            beforeTitle: `Financial literacy for college students`,
        },
        {
            text: `Our Life Skills program goes beyond traditional education, offering college students a weekly guide on how to excel in learning, working, growing, 
            and living. From effective communication to emotional intelligence, these sessions, delivered through immersive tech, provide practical wisdom 
            that's applicable in real-world scenarios. <br  /> Customizable to your institution's unique requirements, this program supports holistic development, 
            preparing students not just for graduation but for life.`,
            title: `<span class="text-primary">Skills for life: </span> learn, work, grow, live`,
            image: `/static/images/programs/skills.png`,
            type: `right`,
            beforeTitle: `Life skills program for college students`,
        }
    ]
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [])
    return (
        <>
            <Grow in={true} timeout={1000}>
                <div>
                    <Banner bg="url('/static/images/programs/programs-bg.webp')" content={content} />
                </div>
            </Grow>
            <section className="main-body">
                <Container maxWidth="lg">
                    {
                        mainContent?.map((item, index) => {
                            return (
                                <Category {...item} comKey={index} />
                            )
                        })
                    }
                </Container>
            </section>
        </>
    )
}
