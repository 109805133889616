import {
    Grid,
    Typography,
    Box,
    Button,
} from '@mui/material'
import { Link } from "react-router-dom";

export default function Category({ text, title, image, type, link, beforeTitle, comKey }) {
    if (type === 'left') {
        return (
            <Grid key={comKey} container spacing={3} className="category">
                <Grid item md={4} sm={12} sx={{ display: 'flex', alignItems: 'center', padding: { md: 8, sm: 2 } }}>
                    <img width="100%" src={image} alt="" />
                </Grid>
                <Grid item md={8} sm={12} className="text-section" sx={{ padding: { md: '40px', sm: '20px', xs: '10px' } }}>
                    <Box className="before-title" component={'h2'}>
                        {beforeTitle}
                    </Box>
                    <Box>
                        <Typography className="title">  <span dangerouslySetInnerHTML={{ __html: title }}></span> </Typography>
                        <Typography className="paragraph">
                            <span dangerouslySetInnerHTML={{ __html: text }}></span>
                        </Typography>
                    </Box>
                    <Button component={Link} to='/contact-us' sx={{ mt: 7 }} className="btn-demo">Ask for a demo</Button>
                </Grid>
            </Grid >
        )
    }
    return (
        <Grid key={comKey} container spacing={3} className="category">
            <Grid item md={8} order={{ md: 1, sm: 2, xs: 2 }} sm={12} className="text-section" sx={{ padding: { md: '40px', sm: '20px', xs: '10px' } }}>
                <Box className="before-title" component={'h2'}>
                    {beforeTitle}
                </Box>
                <Box>
                    <Typography className="title">  <span dangerouslySetInnerHTML={{ __html: title }}></span> </Typography>
                    <Typography className="paragraph">
                        <span dangerouslySetInnerHTML={{ __html: text }}></span>
                    </Typography>
                </Box>
                <Button component={Link} to='/contact-us' sx={{ mt: 7 }} className="btn-demo">Ask for a demo</Button>
            </Grid>
            <Grid order={{ md: 2, sm: 1, xs: 1 }} item md={4} sm={12} sx={{ display: 'flex', alignItems: 'center', padding: { md: 8, sm: 2 } }}>
                <img width="100%" src={image} alt="" />
            </Grid>
        </Grid>
    )
}
